import React, { useEffect } from 'react'
import { track } from '@toasttab/do-secundo-analytics'

import Formik from 'formik'
import { InputChangedEvent, SpiInputState } from '../../types/SPI'
import { PaymentType } from '../CheckoutForm/PaymentInfo/constants'
import { useSentry } from 'banquet-runtime-modules'
import { useSpiData } from './SpiDataProvider'

type props = {
  amexAccepted: boolean
  zipRequired: boolean
  handleSpiFailure: () => void
  onInit?: () => void
} & Formik.FieldProps

export const SPIAnchor = ({
  amexAccepted,
  zipRequired,
  handleSpiFailure,
  field,
  form,
  onInit
}: props) => {
  const { spiBearerToken, paymentIntent, spiSdk, merchantId } = useSpiData()
  const anchorId = 'spi-frame-anchor'
  const { captureMessage } = useSentry()
  useEffect(() => {
    // sdk is undefined only if it failed to load
    if (spiSdk === undefined) {
      captureMessage('SPI SDK failed to load')
      handleSpiFailure()
    }
  }, [spiSdk, handleSpiFailure, captureMessage])

  useEffect(() => {
    let loggerTimeoutRef: number | undefined
    let fallbackTimeoutRef: number | undefined
    let frame: HTMLIFrameElement | undefined
    const setSpiPaymentData = (e: InputChangedEvent) => {
      form.setFieldValue(
        field.name,
        e.content.isValid ? SpiInputState.VALID : SpiInputState.INVALID
      )
      form.setFieldValue('spiPayment', true)
      form.setFieldValue('spiPaymentType', e.content.selectedPaymentMethod)
      form.setFieldValue('paymentType', PaymentType.CREDIT_CARD)
      form.setFieldTouched(field.name, true)
    }
    if (
      spiSdk &&
      merchantId &&
      paymentIntent?.sessionSecret &&
      spiBearerToken
    ) {
      try {
        fallbackTimeoutRef = window.setTimeout(() => {
          track('SPI initialization timed out after 10 seconds')
          captureMessage('SPI initialization timed out after 10 seconds')
          handleSpiFailure()
        }, 10000)

        frame = spiSdk.initialize(
          (initEvent) => {
            if (onInit) {
              onInit()
            }
            clearTimeout(loggerTimeoutRef)
            clearTimeout(fallbackTimeoutRef)
            setSpiPaymentData(initEvent)
            track('SPI initialization succeeded')
            spiSdk.monitor((monitorEvent) => {
              if (monitorEvent.content.selectedPaymentMethod) {
                setSpiPaymentData(monitorEvent)
              }
            })
          },
          () => {
            captureMessage('SPI initialization failed')
            handleSpiFailure()
          },
          {
            domElementId: anchorId,
            merchantId,
            sessionSecret: paymentIntent?.sessionSecret,
            acceptAmex: amexAccepted,
            zipRequired,
            oauthToken: spiBearerToken
          }
        )
      } catch (e) {
        captureMessage('Unexpected error initializing SPI')
        handleSpiFailure()
      }
    }
    return () => {
      try {
        frame && document.removeChild(frame)
        loggerTimeoutRef && clearTimeout(loggerTimeoutRef)
        fallbackTimeoutRef && clearTimeout(fallbackTimeoutRef)
      } catch (e) {}
    }
    // In this case, we do not want exhaustive dependencies, as including field/form causes this hook
    // to rerun each time the form changes- something we explicitly do not want
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    amexAccepted,
    merchantId,
    paymentIntent?.sessionSecret,
    zipRequired,
    spiSdk,
    spiBearerToken
  ])

  return <div id={anchorId} />
}
