import { useEffect } from 'react'
import { dataByTypename } from '../../utils/apollo-helpers'
import { useCreate_Payment_IntentMutation } from '../../apollo/generated/OptWebGraphQLOperations'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider/DDIGlobalsProvider'
import { useSpiAllowed } from './useSpiAllowed'

export const useFetchSessionSecret = ({
  checkGuid,
  partyGuid,
  partyMemberGuid,
  memberAuthToken,
  canCreatePaymentIntent
}: {
  checkGuid?: string | null
  partyGuid?: string
  partyMemberGuid?: string
  memberAuthToken?: string
  canCreatePaymentIntent: boolean
}) => {
  const spiAllowed = useSpiAllowed()
  const {
    optConfig: {
      paymentConfig: { allowPreauth }
    }
  } = useDDIGlobals()
  const [createPaymentIntentMutation, { data, loading, error }] =
    useCreate_Payment_IntentMutation()

  useEffect(() => {
    if (
      partyGuid &&
      partyMemberGuid &&
      memberAuthToken &&
      spiAllowed &&
      canCreatePaymentIntent
    ) {
      createPaymentIntentMutation({
        variables: {
          input: {
            checkGuid,
            partyGuid,
            partyMemberGuid,
            memberAuthToken
          }
        }
      })
    }
  }, [
    createPaymentIntentMutation,
    checkGuid,
    partyGuid,
    partyMemberGuid,
    memberAuthToken,
    spiAllowed,
    allowPreauth,
    canCreatePaymentIntent
  ])

  // @ts-ignore
  const { PaymentIntent, PaymentIntentError } = dataByTypename(
    data?.createPaymentIntent
  )

  return {
    data: PaymentIntent,
    loading,
    error: PaymentIntentError || error
  }
}
