import { useGet_Bearer_TokenQuery } from '@toasttab/do-federated-gateway-apollo/generated/DoFederatedGatewayGraphQLOperations'
import { doFederatedApolloClient } from '../../apollo/do-federated-apollo-client'
import { useSpiAllowed } from './useSpiAllowed'

export const useFetchSpiToken = (): string | undefined => {
  const spiAllowed = useSpiAllowed()
  const { loading, error, data } = useGet_Bearer_TokenQuery({
    client: doFederatedApolloClient,
    skip: !spiAllowed
  })

  if (!spiAllowed || loading) {
    return
  } else if (error) {
    throw new Error(
      'Could not retrieve authentication token for Simplified Payments Integration.'
    )
  } else {
    return data?.opt_getBearerToken
  }
}
