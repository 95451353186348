import * as React from 'react'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'

import Header from '../Header/Header'
import CartPage from '../CartPage/CartPage'
import ConfirmPage from '../ConfirmPage/ConfirmPage'
import AccountPage from '../AccountPage/AccountPage'
import { AccountModeRouter, SearchModeRouter } from '../ModeRouter/ModeRouter'
import { CartProvider } from '../CartProvider/CartProvider'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { AuthenticatedRoute } from '../AuthenticatedRoute/AuthenticatedRoute'
import { useRestaurantStorage } from '../../utils/restaurant-storage'
import { useTabEnabled } from '../../hooks/tabs/useTabEnabled'
import { ConfirmTabOpened } from '../ConfirmTabOpened/ConfirmTabOpened'
import { TabProvider } from '../TabProvider/TabProvider'
import { ErrorModalProvider } from '../ErrorModalProvider/ErrorModalProvider'
import { IframeTracker } from '../IframeTracker/IframeTracker'
import { PartyProvider } from '../PartyProvider/PartyProvider'
import TabCartPage from '../TabCartPage/TabCartPage'
import { useFlag } from '../FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'
import { GiftCardProvider } from '../GiftCardProvider/GiftCardProvider'
import { CornucopiaProvider } from '@local/cornucopia'
import { OPTPartySplashContainer } from '../OPTPartySplashProvider/OPTPartySplashContainer'
import { useGetPartyMode } from '../PartyQuery/PartyQuery'
import { DDIMode } from '../../types/DDIGlobals'
import { UpsellsProvider } from '../UpsellsProvider/UpsellsProvider'
import { ExperimentsProvider } from '../ExperimentsProvider/ExperimentsProvider'
import {
  upsellFilterRules,
  upsellSortRules
} from '../UpsellsProvider/upsell-rules'
import { CreditCardProvider } from '../CreditCardProvider/CreditCardProvider'
import { GuestInfoProvider } from '../../hooks/use-guest-info'
import { ShowForUS } from '../ShowForUS/ShowForUS'
import { getSplashScreen } from './helpers'
import { HeapSideEffects } from './HeapSideEffects/HeapSideEffects'
import { PartyMemberAuthListener } from '../PartyMemberAuthListener/PartyMemberAuthListener'
import Progress from '../Progress/Progress'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import { useSpiSdk } from '../../hooks/SPI/useSpiSdk'
import { track, addEventProperties } from '@toasttab/do-secundo-analytics'
import { useSpiAllowed } from '../../hooks/SPI/useSpiAllowed'
import { TabsSpaSpiListener } from '../SPI/TabsSpaSpiListener'
import { SpiDataProvider } from '../SPI/SpiDataProvider'

const CreateOrderTabPage = React.lazy(() =>
  import('../CreateOrderTabPage/CreateOrderTabPage')
)

const CloseOrderTab = React.lazy(() =>
  import('../CloseOrderTabPage/CloseOrderTabPage')
)

const QRScannerPage = React.lazy(() => import('../QRScannerPage/QRScannerPage'))

const NoOp = () => <></>

const RestaurantPage = ({ match, location }) => {
  const { path } = match
  const { restaurantGuid } = useRestaurant()
  const history = useHistory()
  const restaurantStorage = useRestaurantStorage()
  const tabEnabled = useTabEnabled()
  const noSplashNoAuthEnabled = useFlag(LDFlags.NO_SPLASH_NOAUTH)
  const incrementalAuthEnabled = useFlag(LDFlags.INCREMENTAL_AUTH)
  const useDoCartsEnabled = useFlag(LDFlags.OPT_USE_DO_CARTS)

  const spiAllowed = useSpiAllowed()
  const { optConfig } = useDDIGlobals()
  // preload the Simplified Payments Integration SDK
  useSpiSdk()
  const { mode } = useGetPartyMode()
  const pathBase = '/' ? '' : path

  React.useEffect(() => {
    if (spiAllowed) {
      addEventProperties({ spiEnabled: true })
      track('SPI enabled.')
    } else {
      track('SPI disabled.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spiAllowed])

  React.useEffect(() => {
    addEventProperties({ doCartsEnabled: useDoCartsEnabled })
  }, [useDoCartsEnabled])

  const addItemRoute = `${pathBase}/add/:itemGuid/:itemGroupGuid`

  if (mode === DDIMode.STP) {
    return (
      <>
        <React.Suspense fallback={<Progress />}>
          <AccountModeRouter />
          <TabProvider restaurantStorage={restaurantStorage}>
            <IframeTracker />
            <PartyProvider>
              <SpiDataProvider mode={DDIMode.STP}>
                <ShowForUS>
                  <Header />
                </ShowForUS>
                <GuestInfoProvider>
                  <CreditCardProvider>
                    <OPTPartySplashContainer />
                    <ErrorModalProvider>
                      <PartyMemberAuthListener />
                      <HeapSideEffects />
                      <GiftCardProvider>
                        <Switch>
                          <Route
                            exact
                            path={`${pathBase}/scan`}
                            component={() => <QRScannerPage />}
                          />
                          <Route
                            path={`${pathBase}/confirm/:orderGuid/:checkGuid?`}
                            component={ConfirmPage}
                          />
                          <AuthenticatedRoute
                            path={`${pathBase}/account`}
                            component={AccountPage}
                          />
                          <Route
                            exact
                            path={`${pathBase}/tab/close`}
                            render={() => <CloseOrderTab history={history} />}
                            location={location}
                          />
                          <Route
                            path={path}
                            component={TabCartPage}
                            location={location}
                          />
                          <Redirect
                            to={{
                              pathname: `${pathBase}/tab`,
                              search: location?.search
                            }}
                          />
                        </Switch>
                      </GiftCardProvider>
                    </ErrorModalProvider>
                  </CreditCardProvider>
                </GuestInfoProvider>
              </SpiDataProvider>
            </PartyProvider>
          </TabProvider>
        </React.Suspense>
      </>
    )
  }

  if (mode === DDIMode.MNP) {
    return (
      <>
        <React.Suspense fallback={<Progress />}>
          <AccountModeRouter />
          <TabProvider restaurantStorage={restaurantStorage}>
            <IframeTracker />
            <PartyProvider>
              <SpiDataProvider mode={DDIMode.MNP}>
                <GuestInfoProvider>
                  <SearchModeRouter />
                  <CreditCardProvider>
                    <CornucopiaProvider restaurantGuid={restaurantGuid}>
                      <OPTPartySplashContainer />
                      <ErrorModalProvider>
                        <PartyMemberAuthListener />
                        <HeapSideEffects />
                        <GiftCardProvider>
                          <Switch>
                            <Route
                              exact
                              path={`${pathBase}/scan`}
                              component={() => <QRScannerPage />}
                            />
                            <Route
                              path={`${pathBase}/confirm/:orderGuid/:checkGuid?`}
                              component={ConfirmPage}
                            />
                            <AuthenticatedRoute
                              path={`${pathBase}/account`}
                              component={AccountPage}
                            />
                            <Route
                              exact
                              path={`${pathBase}/tab/close`}
                              render={() => <CloseOrderTab history={history} />}
                            />
                            <Redirect to={path} />
                          </Switch>
                        </GiftCardProvider>
                      </ErrorModalProvider>
                    </CornucopiaProvider>
                  </CreditCardProvider>
                </GuestInfoProvider>
              </SpiDataProvider>
            </PartyProvider>
          </TabProvider>
        </React.Suspense>
      </>
    )
  }

  return (
    <>
      <React.Suspense fallback={<Progress />}>
        <AccountModeRouter />
        <ExperimentsProvider>
          <TabProvider restaurantStorage={restaurantStorage}>
            <CartProvider
              restaurantGuid={restaurantGuid}
              restaurantStorage={restaurantStorage}
            >
              <IframeTracker />
              <PartyProvider>
                <SpiDataProvider mode={DDIMode.OPT}>
                  {incrementalAuthEnabled && <TabsSpaSpiListener />}

                  <GuestInfoProvider>
                    <SearchModeRouter />
                    <UpsellsProvider
                      filterRules={upsellFilterRules}
                      sortRules={upsellSortRules}
                    >
                      <CreditCardProvider>
                        <CornucopiaProvider restaurantGuid={restaurantGuid}>
                          {getSplashScreen({
                            optConfig,
                            mode,
                            noSplashNoAuthEnabled
                          })}
                          <ErrorModalProvider>
                            <HeapSideEffects />
                            <PartyMemberAuthListener />
                            <GiftCardProvider>
                              <Switch>
                                <Route
                                  exact
                                  path={`${pathBase}/scan`}
                                  component={() => <QRScannerPage />}
                                />
                                <Route
                                  exact
                                  path={`${pathBase}/tab/new`}
                                  render={() => <CreateOrderTabPage />}
                                />
                                <Route
                                  path={`${pathBase}/confirm/:orderGuid/:checkGuid?`}
                                  component={ConfirmPage}
                                />
                                <AuthenticatedRoute
                                  path={`${pathBase}/account`}
                                  component={AccountPage}
                                />
                                <Route
                                  path={`${pathBase}/cart`}
                                  component={
                                    tabEnabled ? TabCartPage : CartPage
                                  }
                                />
                                {tabEnabled && (
                                  <Route path={`${pathBase}/tab`}>
                                    <Switch>
                                      <Route
                                        exact
                                        path={`${pathBase}/tab/close`}
                                        render={() => (
                                          <CloseOrderTab history={history} />
                                        )}
                                      />
                                      <Route
                                        exact
                                        path={`${pathBase}/tab/confirm`}
                                        component={ConfirmTabOpened}
                                      />
                                      <Route
                                        exact
                                        path={[
                                          `${pathBase}/tab`,
                                          `${pathBase}/tab/add`
                                        ]}
                                        component={TabCartPage}
                                      />
                                    </Switch>
                                  </Route>
                                )}
                                <Route path={[addItemRoute, path]}>
                                  <Route path={path} component={NoOp} />
                                </Route>
                                <Redirect to={path} />
                              </Switch>
                            </GiftCardProvider>
                          </ErrorModalProvider>
                        </CornucopiaProvider>
                      </CreditCardProvider>
                    </UpsellsProvider>
                  </GuestInfoProvider>
                </SpiDataProvider>
              </PartyProvider>
            </CartProvider>
          </TabProvider>
        </ExperimentsProvider>
      </React.Suspense>
    </>
  )
}

RestaurantPage.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired
}

export default RestaurantPage
