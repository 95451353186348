import * as React from 'react'
import { useLocation } from 'react-router'
import { useInitializeSpi } from './SpiDataProvider'

const checkSpiAnchorMounted = () => {
  return new Promise<any>((resolve) => {
    if (document.querySelector('#spi-frame-anchor')) {
      resolve(true)
    }
    const obs = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        if (document.querySelector('#spi-frame-anchor')) {
          obs.disconnect()
          resolve(true)
        }
      })
    })
    const config = { subtree: true, childList: true }

    obs.observe(document.body, config)
  })
}

export const TabsSpaSpiListener = () => {
  const { pathname } = useLocation()
  const { initializeSpi } = useInitializeSpi()
  React.useEffect(() => {
    let unmountSpi: () => void | undefined
    checkSpiAnchorMounted().then(() => {
      initializeSpi(
        'spi-frame-anchor',
        () => {},
        () => {},
        () => {}
      )
    })

    return () => {
      unmountSpi && unmountSpi()
    }
  }, [pathname, initializeSpi])

  return <></>
}
